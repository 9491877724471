import React from "react";
import { SEO, MarkdownContent, Image } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class AtlantaTeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.bobsteele.com/atlanta-team/"}
        />
        <Grid
          className={"custom-page-atlanta-stylist custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"gaeovbmprebpjnmq"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"ojakpxuhtknwjmcg"}
              style={{ padding: 16 }}
              width={12}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "76ff8d52-90d7-4460-a09b-49854dfce5e8",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"zrjftgidayavopkn"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"hrvmrwmbfmuuitmr"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "fd3e9bce-d318-4b32-8c86-c96c7d575e34",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "671429b1-18b0-4f8c-af42-67cc564f68f4",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"quevazplqrmoagfy"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: "0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "4136a4d8-6bb1-443f-a37e-f692d48d9412",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "2a11f7da-522d-4b03-905b-b5658f3405bb",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"yqwisyqjzonbjnnk"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: "0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "fd225299-43bb-4525-aeaf-d6a40eceb04b",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "b24878a9-6909-4df3-9b23-085945cc74ad",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"tyicxwdaumomrkcm"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"cgbpskyzvjrmxpra"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: "0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "01c298f9-4325-4a04-ba57-5e0fe543e975",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "71ad6bed-8dd4-4bab-8061-3b59547767b2",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"gqsqokruotbhqaax"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "bbde1eb0-667e-41a3-aeb1-0113c96cb7c8",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "4d8b84e8-80e9-4f84-9ea8-d4faaa52f84e",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"rdftwkdbwzqdcrqk"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "45a60961-e137-49d9-ac32-dfc0e747905a",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "2ab88596-2399-46c7-9118-266d50c0e933",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"oxwhwapwvptxlzev"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"ymhnoltkfwrctvox"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "4bdeeee0-6821-40a4-94b7-6e550b95745a",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "87105218-1929-420e-bc04-183e7b810a0f",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"dommbrcudkiystpq"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "e669d597-ea00-402a-a7a8-764f1184cad7",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "e50bc140-8dae-43c1-9f42-93ce02a264ff",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"mlkfmnwglldwndow"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "18c7984d-564f-4ed2-8e99-df852c48a590",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "9003f119-257d-4523-88c6-c1f45773cc7c",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"sjwcizcoqeevwydk"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"ujjschazzkquqysj"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "23861b79-2a98-4fcd-821e-aecb4e3452c9",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "e022af9f-2050-46ea-92be-1705f364fcf4",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"kmjmezmgmymdcyvk"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "4a8e6839-0e30-4ac4-b9f4-5ea81507e6cd",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "71470e04-445e-43c4-814e-003eb72a0fcb",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"qdnrdlktsrdbaimz"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "615a12b3-fe1f-4a7b-8e5f-07e7be8d82a3",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "26edb19c-5ac1-408b-a2cc-b58844f4b4f2",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"fvulxvjqrcwrlrxw"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"wdcadvuzpairabro"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "40ae391f-ee2a-41d7-99b4-ae9f6b36579c",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "4f368d63-6ae5-42ff-b16a-cfb84d87d183",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"cbeetsylfalvzhhg"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "0ab129a5-fe11-411a-94c4-3db91f9b69b1",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "be6752d2-828d-4f60-b807-de81c7c39198",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"bmjztddsffuqmkyj"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "81635da9-0dfe-496d-b3f1-8873d22b8377",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "510bf5a7-a369-4844-9844-25438fae35cf",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"txxebpfgygktiftq"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"kmiogtpronwwuopr"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "dcc7ee14-20c9-4325-9654-86b0db545a92",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "bbe19387-4006-489e-a0a7-8559d5aa6ae8",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"odnegvvwyoigzaid"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "f2317299-def2-4f87-bd18-5801ce7d5d13",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "635e49ed-2b27-4e9c-b4c2-a93eba20ee9a",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"pvbjjpyrnfgledlr"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "559ee8ff-4cb3-4055-833f-11562c833ae0",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "364ac36a-0844-4e82-a54f-1fbeece79200",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"zdfdzxwgmuezxlkf"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"pndraeewjyalqygo"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "4d070d0b-70eb-4297-beae-07a78623e037",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "d21cd12b-43d2-4b8e-806d-f7314e12d679",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"qqqcgeuhfskagdfn"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "f6b5daf1-5cd6-4251-8544-2f73b3554ab8",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "54aa2dfa-79a8-4c87-bc83-236151d17f0d",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"tfvrbgtyxtqtjqce"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "1b6530a8-3c33-433e-b29d-00e7c82059ee",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "fb2cedb5-4aa1-41be-94de-7c909ab50e96",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"nljzqlrdgofhxtlz"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"adbszopxoikiyukf"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "31ce191e-856c-46aa-8615-ba568d5b856c",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "31aa9c22-b0b2-4883-9415-913d4731cd80",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"vickvxlypqzlrajf"}
              style={{ padding: 16 }}
              width={4}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    boxShadow: " 0 0 0 2px #C7A975",
                    maxHeight: "600px",
                    minHeight: "240px",
                    backgroundSize: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "9b26a41e-272b-4995-a164-c7d7182a0be7",
                    numToSelect: 1,
                  })}
                  background={true}
                >
                  <MarkdownContent
                    className={"text-block"}
                    style={{
                      top: 0,
                      left: 0,
                      color: "#FFFFFF",
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      padding: "16px",
                      position: "absolute",
                      overflowY: "scroll",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "MarkdownContent",
                      componentId: "95a51e67-71c0-41e2-935e-dbc6c3d4ef97",
                      defaultValue: "",
                    })}
                  />
                </Image>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 26942 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
